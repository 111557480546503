import {Link} from 'gatsby'
import React from 'react'
import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'

import styles from './grid-preview.module.css'

function ProjectPreview (props) {
  return (
    <Link className={styles.root} to={`/project/${props.slug.current}`}>
      <article className={styles.portArtical}>
        <h3 className={styles.title}>{props.title}</h3>
        <div className={styles.leadMediaThumb}>
          {props.featuredImage && props.featuredImage.asset && (
            <img
              className={styles.imgPort}
              objectFit='cover'
              objectPosition='50% 50%'
              src={imageUrlFor(buildImageObj(props.featuredImage))
                // .width(600)
                // .height(Math.floor((9 / 16) * 600))
                .url()}
              alt={props.featuredImage.alt}
            />
          )}
        </div>
      </article>
      <h3 className={styles.mobileTitle}>{props.title}</h3>

    </Link>
  )
}

export default ProjectPreview
