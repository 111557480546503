import React from 'react'
import {graphql, Link} from 'gatsby'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from '../lib/helpers'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import ProjectPreviewGrid from '../components/home-preview-mason'
import SEO from '../components/seo'
import Layout from '../containers/layout'

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
      title
      description
      keywords
    }
    projects: allSanityProject(
      limit: 9
      filter: {featured: {elemMatch: {title: {eq: "Featured on Home Page"}}}}, sort: {fields: featuredOrder___order, order: ASC}
    ) {
      edges {
        node {
          id
          mainImage {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
            alt
          }
          featuredImage {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`

const IndexPage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const projectNodes = (data || {}).projects
    ? mapEdgesToNodes(data.projects)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : []

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <Layout>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <Container>
        <section className='siteHero'>
          <h1 className='siteTitle'>{site.title}</h1>
          <h2 className='siteSubtitle'>{site.description}</h2>

          <h3 className='siteDescr'>Creative design should evoke an emotional response on an aesthetic level. That’s when you know it works.</h3>
        </section>
        {projectNodes && (
          <ProjectPreviewGrid
            title='Featured projects'
            nodes={projectNodes}
            browseMoreHref='/archive/'
          />
        )}
        <section id='chat'>
          <h2>
          Aloha, Lets Chat!
          </h2>
          <p>
          One of the challenges of creative marketing is expressing your unique vision in a style that stands out from the crowd. To reach a specific audience, the form of expression must do more than merely embellish your message, it must reveal substance as well. Innovative design makes a statement about you that will leave an impression.
          </p>
          <article className='contactLockup'>
            <Link className='cta' to='/contact/'>Contact Me</Link>
            <a className='cta2' target='_blank' to='JeffHilliard.pdf'>Download my Resume</a>
          </article>
        </section>
      </Container>

    </Layout>
  )
}

export default IndexPage
