import {Link} from 'gatsby'
import React from 'react'
import ProjectPreview from './grid-project-preview'
// const i = '0'             <div className={styles.div[i]}>

import styles from './home-preview-mason.module.css'

function ProjectPreviewMason (props) {
  return (
    <div className={styles.root}>
      {props.title && <h2 className={styles.headline}>{props.title}</h2>}

      <div className={styles.parent}>
        {props.nodes &&
          props.nodes.map((node, idx) => (
            <div className={`Grid-div${idx}`}>
              <ProjectPreview {...node} />
            </div>
          ))}
      </div>
      {props.browseMoreHref && (
        <div className={styles.browseMoreNav}>
          <Link to={props.browseMoreHref}>View More Projects</Link>
        </div>
      )}
    </div>

  )
}

ProjectPreviewMason.defaultProps = {
  title: '',
  nodes: [],
  browseMoreHref: ''
}

export default ProjectPreviewMason
